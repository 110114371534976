// eslint-disable-next-line no-unused-vars
( ( {hooks: {addFilter, addAction}} ) => {
  const sliderExcludes = ['e-sld--slider-type-two'];
  addFilter( 'b3rg_slider_exclude', 'b3rg/slider', () => sliderExcludes );

  const breakpoints = {
    mobile: 576,
    'tablet-portrait': 992,
    'tablet-landscape': 1200,
    desktop: 9999,
  };
  addFilter( 'b3rg_slider_breakpoints', 'b3rg/slider', () => breakpoints );

// eslint-disable-next-line no-undef
} )( feWp );
